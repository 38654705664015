@import (reference, less) "~@/assets/defines";

.widget-wrapper {
  .widget-actions {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;

    .action {
      font-size: 24px;
      cursor: pointer;
      line-height: 100%;
      display: block;
      padding: 4px 10px 3px;

      &:focus {
        background-color: rgba(0, 0, 0, 0.1);
      }

      &:hover {
        background-color: transparent;
        color: @blue;
      }

      &:active {
        filter: brightness(75%);
      }
    }
  }

  .parameter-container {
    margin: 0 15px;
  }

  .body-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .body-row {
      flex: 0 1 auto;
    }

    .body-row-auto {
      flex: 1 1 auto;
    }
  }

  .spinner-container {
    position: relative;

    .spinner {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  .scrollbox:empty {
    padding: 0 !important;
    font-size: 1px !important;
  }

  .widget-text {
    :first-child {
      margin-top: 0;
    }
    :last-child {
      margin-bottom: 0;
    }
  }
}

.editing-mode {
  .widget-menu-remove {
    display: block;
  }

  .query-link {
    pointer-events: none;
    cursor: move;
  }

  .th-title {
    cursor: move;
  }

  .refresh-indicator {
    transition-duration: 0s;

    .rd-timer {
      display: none;
    }

    .refresh-indicator-mini();
  }
}

.refresh-indicator {
  font-size: 18px;
  color: #86a1af;
  transition: all 100ms linear;
  transition-delay: 150ms; // waits for widget-menu to fade out before moving back over it
  transform: translateX(22px);
  position: absolute;
  right: 29px;
  top: 8px;
  display: flex;
  flex-direction: row-reverse;

  .refresh-icon {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 0px;
      right: 0;
      width: 24px;
      height: 24px;
      background-color: #e8ecf0;
      border-radius: 50%;
      transition: opacity 100ms linear;
      transition-delay: 150ms;
    }

    i {
      height: 24px;
      width: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .rd-timer {
    font-size: 13px;
    display: inline-block;
    font-variant-numeric: tabular-nums;
    opacity: 0;
    transform: translateX(-6px);
    transition: all 100ms linear;
    transition-delay: 150ms;
    color: #bbbbbb;
    background-color: rgba(255, 255, 255, 0.9);
    padding-left: 2px;
    padding-right: 1px;
    margin-right: -4px;
    margin-top: 2px;
  }

  .widget-visualization[data-refreshing="false"] & {
    display: none;
  }
}

.refresh-indicator-mini() {
  font-size: 13px;
  transition-delay: 0s;
  color: #bbbbbb;
  transform: translateY(-4px);

  .refresh-icon:before {
    transition-delay: 0s;
    opacity: 0;
  }

  .rd-timer {
    transition-delay: 0s;
    opacity: 1;
    transform: translateX(0);
  }
}

.tile {
  .widget-menu-regular,
  .btn__refresh {
    opacity: 0 !important;
    transition: opacity 0.35s ease-in-out;
  }

  .t-header {
    .th-title {
      padding-right: 23px; // no overlap on RefreshIndicator

      .hidden-print {
        margin-bottom: 0;
      }

      .query-link {
        color: fade(@redash-black, 80%);
        font-size: 15px;
        font-weight: 500;

        &:not(.visualization-name) {
          color: fade(@redash-black, 50%);
        }
      }
    }

    .query--description {
      font-size: 14px;
      line-height: 1.5;
      font-style: italic;

      p {
        margin-bottom: 0;
      }
    }
  }

  .t-header.widget {
    padding: 15px;
  }

  &:hover,
  &:focus,
  &:active,
  &:focus-within {
    .widget-menu-regular,
    .btn__refresh {
      opacity: 1 !important;
      transition: opacity 0.35s ease-in-out;
    }

    .refresh-indicator {
      .refresh-indicator-mini();
    }
  }

  .tile__bottom-control {
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn-transparent {
      &:first-child {
        margin-left: -10px;
      }

      &:last-child {
        margin-right: -10px;
      }
    }

    a,
    .plain-button {
      color: fade(@redash-black, 65%);

      &:hover,
      &:focus {
        color: fade(@redash-black, 95%);
      }
    }
  }
}
