.word-cloud-visualization-container {
  overflow: hidden;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    transform-origin: center center;
    flex: 0 0 auto;
  }
}
