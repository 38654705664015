.ant-radio-group.text-alignment-select {
  display: flex;
  align-items: stretch;
  justify-content: stretch;

  .ant-radio-button-wrapper {
    flex-grow: 1;
    text-align: center;
    // fit <Input> height
    height: 35px;
    line-height: 33px;
  }
}
