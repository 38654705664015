.color-swatch {
  display: inline-block;
  box-sizing: border-box;
  vertical-align: middle;
  border-radius: 2px;
  overflow: hidden;
  width: 12px;

  @cell-size: 12px;
  @cell-color: rgba(0, 0, 0, 0.1);

  background-color: transparent;
  background-image:
    linear-gradient(45deg, @cell-color 25%, transparent 25%),
    linear-gradient(-45deg, @cell-color 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, @cell-color 75%),
    linear-gradient(-45deg, transparent 75%, @cell-color 75%);
  background-size: @cell-size @cell-size;
  background-position: 0 0, 0 @cell-size/2, @cell-size/2 -@cell-size/2, -@cell-size/2 0px;

  &:before {
    content: "";
    display: block;
    padding-top: ~"calc(100% - 2px)";
    background-color: inherit;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    overflow: hidden;
  }
}
