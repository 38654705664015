@import "../variables";

.cornelius-container {
  .cornelius-title {
    text-align: center;
    padding-bottom: 10px;
    font-weight: bold;
    font-size: 14pt;
    color: @table-header-color;
    border-collapse: collapse;
  }

  .cornelius-table {
    font-size: 9pt;
    border-spacing: 0;
    border: 1px solid #e4e4e4;
    border-collapse: collapse;

    th,
    td {
      text-align: center;
      padding: 10px;
      border: 1px solid #e4e4e4;
      color: @table-header-color;
      font-weight: bold;
    }

    .cornelius-label {
      text-align: left;
    }

    .cornelius-empty,
    .cornelius-percentage,
    .cornelius-absolute {
      font-weight: normal;
      border: none;
    }

    .cornelius-label,
    .cornelius-people,
    .cornelius-stage {
      font-weight: bold;
      color: @table-header-color;
    }

    .cornelius-percentage,
    .cornelius-absolute {
      &.cornelius-white-text {
        text-shadow: 1px 1px 1px #000000;
      }
    }
  }
}
