@media (min-width: 992px) {
  .edit-visualization-dialog {
    display: flex;
    height: 100%;

    .visualization-settings {
      padding-right: 12px;
      width: 40%;
      overflow: auto;
    }

    .visualization-preview {
      padding-left: 12px;
      width: 60%;
      overflow: auto;
    }
  }
}
