.counter-visualization-container {
  display: block;
  text-align: center;
  padding: 15px 10px;
  overflow: hidden;
  position: relative;

  .counter-visualization-content {
    margin: 0;
    padding: 0;
    font-size: 80px;
    line-height: normal;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .counter-visualization-value,
    .counter-visualization-target {
      font-size: 1em;
      display: block;
    }

    .counter-visualization-label {
      font-size: 0.5em;
      display: block;
    }

    .counter-visualization-target {
      color: #ccc;
    }

    .counter-visualization-label {
      font-size: 0.5em;
      display: block;
    }
  }

  &.trend-positive .counter-visualization-value {
    color: #5cb85c;
  }

  &.trend-negative .counter-visualization-value {
    color: #d9534f;
  }
}
