@import (reference, less) "~@/assets/defines";

.embed-query-dialog {
  label {
    font-weight: normal;
  }

  .size-input {
    width: 72px;
  }
}
