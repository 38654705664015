/* Top-level defines for styles and mixins */
/* --------------------------------------------------------
    For-plugin
-----------------------------------------------------------*/
/* --------------------------------------------------------
    Prefixes
-----------------------------------------------------------*/
/* --------------------------------------------------------
    Paths
-----------------------------------------------------------*/
/* --------------------------------------------------------
    Container
-----------------------------------------------------------*/
/* --------------------------------------------------------
    Template Variables
-----------------------------------------------------------*/
/* --------------------------------------------------------
    Branding
-----------------------------------------------------------*/
/* --------------------------------------------------------
    Font
-----------------------------------------------------------*/
/* --------------------------------------------------------
    Animation
-----------------------------------------------------------*/
/* --------------------------------------------------------
    Typograpgy
-----------------------------------------------------------*/
/* --------------------------------------------------------
    Form
-----------------------------------------------------------*/
/* --------------------------------------------------------
    Colors
-----------------------------------------------------------*/
/** Form States **/
/* --------------------------------------------------------
    Alert
-----------------------------------------------------------*/
/* --------------------------------------------------------
    Bootstrap Brands
-----------------------------------------------------------*/
/* --------------------------------------------------------
    Border Radius
-----------------------------------------------------------*/
/* --------------------------------------------------------
    Dropdown
-----------------------------------------------------------*/
/* --------------------------------------------------------
    Page Header
-----------------------------------------------------------*/
/* --------------------------------------------------------
    Buttons
-----------------------------------------------------------*/
/* --------------------------------------------------------
    Tables
-----------------------------------------------------------*/
/* --------------------------------------------------------
    Pagination
-----------------------------------------------------------*/
/* --------------------------------------------------------
    Thumbnail
-----------------------------------------------------------*/
/* --------------------------------------------------------
    Carousel
-----------------------------------------------------------*/
/* --------------------------------------------------------
    Modal
-----------------------------------------------------------*/
/* --------------------------------------------------------
    Tooltips
-----------------------------------------------------------*/
/* --------------------------------------------------------
    Popobver
-----------------------------------------------------------*/
/* --------------------------------------------------------
    Jumbotron
-----------------------------------------------------------*/
/* --------------------------------------------------------
    List Group
-----------------------------------------------------------*/
/* --------------------------------------------------------
    Badges
-----------------------------------------------------------*/
/* --------------------------------------------------------
    Misc
-----------------------------------------------------------*/
.label-default {
  background-color: #828282;
}
.label-default[href]:hover,
.label-default[href]:focus {
  background-color: #696969;
}
.label-primary {
  background-color: #2196F3;
}
.label-primary[href]:hover,
.label-primary[href]:focus {
  background-color: #0c7cd5;
}
.label-success {
  background-color: #4CAF50;
}
.label-success[href]:hover,
.label-success[href]:focus {
  background-color: #3d8b40;
}
.label-info {
  background-color: #00BCD4;
}
.label-info[href]:hover,
.label-info[href]:focus {
  background-color: #008fa1;
}
.label-warning {
  background-color: #FF9800;
}
.label-warning[href]:hover,
.label-warning[href]:focus {
  background-color: #cc7a00;
}
.label-danger {
  background-color: #F44336;
}
.label-danger[href]:hover,
.label-danger[href]:focus {
  background-color: #ea1c0d;
}
@media print {
  a[href]:after {
    content: none !important;
  }
}
/* --------------------------------------------------------
    Font Face
-----------------------------------------------------------*/
/* --------------------------------------------------------
    Button Varients
-----------------------------------------------------------*/
/* --------------------------------------------------------
    CSS Transform - Scale and Rotate
-----------------------------------------------------------*/
/* --------------------------------------------------------
    CSS Animations based on animate.css
-----------------------------------------------------------*/
/* Icons */
.icon-tabler {
  display: inline-block;
  vertical-align: middle;
}
/* Breadcrumb */
.ant-breadcrumb span.ant-breadcrumb-link {
  font-weight: bold;
}
/* Utility styles and mixins */
*,
button,
input,
i,
a {
  -webkit-font-smoothing: antialiased;
}
*,
*:active,
*:hover {
  outline: none !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}
html {
  overflow-x: hidden\0/;
  -ms-overflow-style: auto;
}
html,
body {
  min-height: 100vh;
}
body {
  padding-top: 0;
  margin: 0;
  background: #f6f8f9;
  font-family: sans-serif;
  position: relative;
}
body #application-root {
  padding-bottom: 15px;
}
iframe {
  border: 1px dashed #cacaca;
  padding: 0.5rem;
}
#application-root {
  min-height: 100vh;
}
#application-root,
#app-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
strong {
  font-weight: 500;
}
#content {
  position: relative;
  padding-top: 30px;
  padding-bottom: 30px;
}
@media (min-width: 769px) {
  #content {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 1280px) {
  #content {
    margin-left: 240px;
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  #content {
    margin-left: 64px;
  }
}
@media (max-width: 768px) {
  #content {
    margin-left: 0;
  }
}
.container.c-boxed {
  max-width: 1170px;
}
.settings-screen .container,
.home-page .container,
.page-dashboard-list .container,
.page-queries-list .container,
.page-alerts-list .container,
.alert-page .container,
.admin-page-layout .container {
  display: block;
  padding: 1.5rem;
}
.scrollbox {
  overflow: auto;
  position: relative;
}
.clickable {
  cursor: pointer;
}
button.clickable:disabled {
  cursor: not-allowed;
}
.resize-vertical {
  resize: vertical !important;
  transition: height 0s !important;
}
.resize-horizontal {
  resize: horizontal !important;
  transition: width 0s !important;
}
.resize-both,
.resize-vertical.resize-horizontal {
  resize: both !important;
  transition: height 0s, width 0s !important;
}
.bg-ace {
  background-color: rgba(102, 136, 153, 0.12) !important;
}
.rg-top span,
.rg-bottom span {
  height: 3px;
  border-color: #b1c1ce;
}
.rg-bottom {
  bottom: 15px;
}
.rg-bottom span {
  margin: 1.5px 0 0 -10px;
}
text.slicetext {
  text-shadow: 1px 1px 5px #333;
}
.markdown strong {
  font-weight: bold;
}
.markdown img {
  max-width: 100%;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  background-color: rgba(102, 136, 153, 0.15);
  color: #111;
}
.profile__image--sidebar {
  border-radius: 100%;
  margin-right: 3px;
  margin-top: -2px;
}
.profile__image--settings {
  border-radius: 100%;
}
.profile__image_thumb {
  border-radius: 100%;
  margin-right: 3px;
  margin-top: -2px;
  width: 20px;
  height: 20px;
}
.error-state {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  margin-top: 25vh;
  padding: 35px;
  font-size: 14px;
  line-height: 21px;
}
.error-state .error-state__icon .zmdi {
  font-size: 64px;
  color: #668899;
}
@media (max-width: 767px) {
  .error-state {
    margin-top: 10vh;
  }
}
.warning-icon-danger {
  color: #F44336 !important;
}
.page-title {
  display: flex;
  align-items: center;
}
.page-title .label {
  margin-top: 3px;
  display: inline-block;
}
.page-title .favorites-control {
  font-size: 19px;
  margin-right: 10px;
}
.page-header--new h3 {
  margin: 0.2em 0;
  line-height: 1.3;
  font-weight: 500;
}
.select-option-divider {
  margin: 10px 0 !important;
}
.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.flex-fill {
  flex: 1 1 auto !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-stretch {
  align-items: stretch !important;
}
.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-content-stretch {
  align-content: stretch !important;
}
.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
@media print {
  @page {
    margin: 0;
    padding: 0;
    size: auto;
  }
  body,
  #content,
  .container {
    margin: 0mm 0mm 0mm 0mm !important;
    padding: 0mm !important;
  }
  #header,
  #sidebar,
  #chat,
  .growl-animated,
  [data-action="print"] {
    display: none !important;
  }
  /* --------------------------------------------------------
        Invoice
    -----------------------------------------------------------*/
  .invoice {
    padding: 30px !important;
    -webkit-print-color-adjust: exact !important;
  }
  .invoice .card-header {
    background: #eee !important;
    padding: 20px;
    margin-bottom: 20px;
    margin: -60px -30px 25px -30px;
  }
  .invoice .page-header {
    display: none;
  }
  .invoice .highlight {
    background: #eee !important;
  }
}
/* --------------------------------------------------------
    Generate Margin Classes (0px - 25px)
    margin, margin-top, margin-bottom, margin-left, margin-right
-----------------------------------------------------------*/
.m-0 {
  margin: 0px !important;
}
.m-t-0 {
  margin-top: 0px !important;
}
.m-b-0 {
  margin-bottom: 0px !important;
}
.m-l-0 {
  margin-left: 0px !important;
}
.m-r-0 {
  margin-right: 0px !important;
}
.m-5 {
  margin: 5px !important;
}
.m-t-5 {
  margin-top: 5px !important;
}
.m-b-5 {
  margin-bottom: 5px !important;
}
.m-l-5 {
  margin-left: 5px !important;
}
.m-r-5 {
  margin-right: 5px !important;
}
.m-10 {
  margin: 10px !important;
}
.m-t-10 {
  margin-top: 10px !important;
}
.m-b-10 {
  margin-bottom: 10px !important;
}
.m-l-10 {
  margin-left: 10px !important;
}
.m-r-10 {
  margin-right: 10px !important;
}
.m-15 {
  margin: 15px !important;
}
.m-t-15 {
  margin-top: 15px !important;
}
.m-b-15 {
  margin-bottom: 15px !important;
}
.m-l-15 {
  margin-left: 15px !important;
}
.m-r-15 {
  margin-right: 15px !important;
}
.m-20 {
  margin: 20px !important;
}
.m-t-20 {
  margin-top: 20px !important;
}
.m-b-20 {
  margin-bottom: 20px !important;
}
.m-l-20 {
  margin-left: 20px !important;
}
.m-r-20 {
  margin-right: 20px !important;
}
.m-25 {
  margin: 25px !important;
}
.m-t-25 {
  margin-top: 25px !important;
}
.m-b-25 {
  margin-bottom: 25px !important;
}
.m-l-25 {
  margin-left: 25px !important;
}
.m-r-25 {
  margin-right: 25px !important;
}
.m-30 {
  margin: 30px !important;
}
.m-t-30 {
  margin-top: 30px !important;
}
.m-b-30 {
  margin-bottom: 30px !important;
}
.m-l-30 {
  margin-left: 30px !important;
}
.m-r-30 {
  margin-right: 30px !important;
}
.m-2 {
  margin: 2px;
}
/* --------------------------------------------------------
    Generate Padding Classes (0px - 25px)
    padding, padding-top, padding-bottom, padding-left, padding-right
-----------------------------------------------------------*/
.p-0 {
  padding: 0px !important;
}
.p-t-0 {
  padding-top: 0px !important;
}
.p-b-0 {
  padding-bottom: 0px !important;
}
.p-l-0 {
  padding-left: 0px !important;
}
.p-r-0 {
  padding-right: 0px !important;
}
.p-5 {
  padding: 5px !important;
}
.p-t-5 {
  padding-top: 5px !important;
}
.p-b-5 {
  padding-bottom: 5px !important;
}
.p-l-5 {
  padding-left: 5px !important;
}
.p-r-5 {
  padding-right: 5px !important;
}
.p-10 {
  padding: 10px !important;
}
.p-t-10 {
  padding-top: 10px !important;
}
.p-b-10 {
  padding-bottom: 10px !important;
}
.p-l-10 {
  padding-left: 10px !important;
}
.p-r-10 {
  padding-right: 10px !important;
}
.p-15 {
  padding: 15px !important;
}
.p-t-15 {
  padding-top: 15px !important;
}
.p-b-15 {
  padding-bottom: 15px !important;
}
.p-l-15 {
  padding-left: 15px !important;
}
.p-r-15 {
  padding-right: 15px !important;
}
.p-20 {
  padding: 20px !important;
}
.p-t-20 {
  padding-top: 20px !important;
}
.p-b-20 {
  padding-bottom: 20px !important;
}
.p-l-20 {
  padding-left: 20px !important;
}
.p-r-20 {
  padding-right: 20px !important;
}
.p-25 {
  padding: 25px !important;
}
.p-t-25 {
  padding-top: 25px !important;
}
.p-b-25 {
  padding-bottom: 25px !important;
}
.p-l-25 {
  padding-left: 25px !important;
}
.p-r-25 {
  padding-right: 25px !important;
}
.p-30 {
  padding: 30px !important;
}
.p-t-30 {
  padding-top: 30px !important;
}
.p-b-30 {
  padding-bottom: 30px !important;
}
.p-l-30 {
  padding-left: 30px !important;
}
.p-r-30 {
  padding-right: 30px !important;
}
/* --------------------------------------------------------
    Generate Font-Size Classes (8px - 20px)
-----------------------------------------------------------*/
.f-8 {
  font-size: 8px !important;
}
.f-9 {
  font-size: 9px !important;
}
.f-10 {
  font-size: 10px !important;
}
.f-11 {
  font-size: 11px !important;
}
.f-12 {
  font-size: 12px !important;
}
.f-13 {
  font-size: 13px !important;
}
.f-14 {
  font-size: 14px !important;
}
.f-15 {
  font-size: 15px !important;
}
.f-16 {
  font-size: 16px !important;
}
.f-17 {
  font-size: 17px !important;
}
.f-18 {
  font-size: 18px !important;
}
.f-19 {
  font-size: 19px !important;
}
.f-20 {
  font-size: 20px !important;
}
.f-inherit {
  font-size: inherit !important;
}
/* --------------------------------------------------------
    Font Weight
-----------------------------------------------------------*/
.f-300 {
  font-weight: 300 !important;
}
.f-400 {
  font-weight: 400 !important;
}
.f-500 {
  font-weight: 500 !important;
}
.f-700 {
  font-weight: 700 !important;
}
/* --------------------------------------------------------
    Position
-----------------------------------------------------------*/
.p-relative {
  position: relative !important;
}
.p-absolute {
  position: absolute !important;
}
.p-fixed {
  position: fixed !important;
}
.p-static {
  position: static !important;
}
/* --------------------------------------------------------
    Overflow
-----------------------------------------------------------*/
.o-hidden {
  overflow: hidden !important;
}
.o-visible {
  overflow: visible !important;
}
.o-auto {
  overflow: auto !important;
}
/* --------------------------------------------------------
    Display
-----------------------------------------------------------*/
.di-block {
  display: inline-block !important;
}
.d-block {
  display: block;
}
/* --------------------------------------------------------
    Background Colors and Colors
-----------------------------------------------------------*/
.bg-white {
  background-color: #ffffff !important;
}
.c-white {
  color: #ffffff !important;
}
.bg-ace {
  background-color: #f8f8f8 !important;
}
.c-ace {
  color: #f8f8f8 !important;
}
.bg-black {
  background-color: #000000 !important;
}
.c-black {
  color: #000000 !important;
}
.bg-brown {
  background-color: #795548 !important;
}
.c-brown {
  color: #795548 !important;
}
.bg-pink {
  background-color: #E91E63 !important;
}
.c-pink {
  color: #E91E63 !important;
}
.bg-red {
  background-color: #F44336 !important;
}
.c-red {
  color: #F44336 !important;
}
.bg-blue {
  background-color: #2196F3 !important;
}
.c-blue {
  color: #2196F3 !important;
}
.bg-purple {
  background-color: #9C27B0 !important;
}
.c-purple {
  color: #9C27B0 !important;
}
.bg-deeppurple {
  background-color: #673AB7 !important;
}
.c-deeppurple {
  color: #673AB7 !important;
}
.bg-lightblue {
  background-color: #03A9F4 !important;
}
.c-lightblue {
  color: #03A9F4 !important;
}
.bg-cyan {
  background-color: #00BCD4 !important;
}
.c-cyan {
  color: #00BCD4 !important;
}
.bg-teal {
  background-color: #009688 !important;
}
.c-teal {
  color: #009688 !important;
}
.bg-green {
  background-color: #4CAF50 !important;
}
.c-green {
  color: #4CAF50 !important;
}
.bg-lightgreen {
  background-color: #8BC34A !important;
}
.c-lightgreen {
  color: #8BC34A !important;
}
.bg-lime {
  background-color: #CDDC39 !important;
}
.c-lime {
  color: #CDDC39 !important;
}
.bg-yellow {
  background-color: #FFEB3B !important;
}
.c-yellow {
  color: #FFEB3B !important;
}
.bg-amber {
  background-color: #FFC107 !important;
}
.c-amber {
  color: #FFC107 !important;
}
.bg-orange {
  background-color: #FF9800 !important;
}
.c-orange {
  color: #FF9800 !important;
}
.bg-deeporange {
  background-color: #FF5722 !important;
}
.c-deeporange {
  color: #FF5722 !important;
}
.bg-gray {
  background-color: #9E9E9E !important;
}
.c-gray {
  color: #9E9E9E !important;
}
.bg-bluegray {
  background-color: #607D8B !important;
}
.c-bluegray {
  color: #607D8B !important;
}
.bg-indigo {
  background-color: #3F51B5 !important;
}
.c-indigo {
  color: #3F51B5 !important;
}
/* --------------------------------------------------------
    Background Colors
-----------------------------------------------------------*/
.bg-brand {
  background-color: #191C22;
}
.bg-black-trp {
  background-color: rgba(0, 0, 0, 0.12) !important;
}
/* --------------------------------------------------------
    Borders
-----------------------------------------------------------*/
.b-0 {
  border: 0 !important;
}
/* --------------------------------------------------------
    Width
-----------------------------------------------------------*/
.w-100 {
  width: 100% !important;
}
.w-50 {
  width: 50% !important;
}
.w-25 {
  width: 25% !important;
}
/* --------------------------------------------------------
    Border Radius
-----------------------------------------------------------*/
.brd-2 {
  border-radius: 2px;
}
/* --------------------------------------------------------
    Alignment
-----------------------------------------------------------*/
.va-top {
  vertical-align: top;
}
/* --------------------------------------------------------
    Screen readers
-----------------------------------------------------------*/
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
.four-zero {
  background: #ffffff;
  box-shadow: 0 1px 11px rgba(0, 0, 0, 0.27);
  border-radius: 2px;
  position: absolute;
  top: 50%;
  margin-top: -150px;
  text-align: center;
  padding: 15px;
  height: 300px;
  width: 500px;
  left: 50%;
  color: #333;
  margin-left: -250px;
}
.four-zero h2 {
  font-size: 130px;
}
@media (max-width: 767px) {
  .four-zero {
    width: calc(100% - 40px);
    left: 20px;
    margin-left: 0;
    height: 260px;
    margin-top: -130px;
  }
  .four-zero h2 {
    font-size: 90px;
  }
}
.four-zero h2 {
  line-height: 100%;
  font-weight: 100;
}
.four-zero small {
  display: block;
  font-size: 26px;
  margin-top: -10px;
}
.four-zero footer {
  background: #f8f8f8;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 10px;
}
.four-zero footer > a {
  font-size: 21px;
  display: inline-block;
  color: #333;
  margin: 0 1px;
  line-height: 40px;
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.09);
  border-radius: 50%;
  text-align: center;
}
.four-zero footer > a:hover {
  background: rgba(0, 0, 0, 0.2);
}
.ie-warning {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background: #000000;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;
  font-family: "Courier New", Courier, monospace;
  padding: 50px 0;
}
.ie-warning p {
  font-size: 17px;
}
.ie-warning .iew-container {
  min-width: 1024px;
  width: 100%;
  height: 200px;
  background: #fff;
  margin: 50px 0;
}
.ie-warning .iew-download {
  list-style: none;
  padding: 30px 0;
  margin: 0 auto;
  width: 720px;
}
.ie-warning .iew-download > li {
  float: left;
  vertical-align: top;
}
.ie-warning .iew-download > li > a {
  display: block;
  color: #000;
  width: 140px;
  font-size: 15px;
  padding: 15px 0;
}
.ie-warning .iew-download > li > a > div {
  margin-top: 10px;
}
.ie-warning .iew-download > li > a:hover {
  background-color: #eee;
}
/* Application layout, navigation */
body #application-root {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  padding-bottom: 0 !important;
  height: 100vh;
}
body #application-root .application-layout-side-menu {
  height: 100vh;
  position: relative;
}
@media (max-width: 767px) {
  body #application-root .application-layout-side-menu {
    display: none;
  }
}
body #application-root .application-layout-top-menu {
  height: 49px;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 1000;
}
@media (max-width: 767px) {
  body #application-root .application-layout-top-menu {
    display: block;
  }
}
body.headless #application-root .application-layout-side-menu,
body.headless #application-root .application-layout-top-menu {
  display: none !important;
}
@media only percy {
  body #application-root {
    height: auto;
  }
  body #application-root .application-layout-side-menu {
    height: auto;
  }
}
.desktop-navbar {
  height: 100vh;
  position: relative;
}
.desktop-navbar .desktop-navbar-main-menu {
  height: 100%;
}
.desktop-navbar .desktop-navbar-profile-menu {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.desktop-navbar .ant-menu-item.ant-menu-item-only-child {
  display: block;
  padding: 1rem;
  height: 5.6rem;
  text-align: center;
}
.desktop-navbar .ant-menu-item.ant-menu-item-only-child img {
  max-height: 3.6rem;
}
.desktop-navbar .ant-menu-item.ant-menu-item-only-child .ant-menu-title-content {
  padding-left: 0;
}
.desktop-navbar .ant-menu-item {
  height: 3rem;
}
.desktop-navbar .ant-menu-item,
.desktop-navbar .ant-menu-submenu {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}
.desktop-navbar .ant-menu-item .ant-menu-title-content,
.desktop-navbar .ant-menu-submenu .ant-menu-title-content {
  padding-left: 0.5rem;
}
.desktop-navbar .ant-menu-submenu-title {
  background-color: rgba(255, 255, 255, 0.05);
  font-weight: 500;
}
.desktop-navbar .ant-menu-item-divider {
  background-color: rgba(255, 255, 255, 0.15);
  margin: 0.5rem 1rem;
}
.mobile-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #000000;
  box-shadow: 0 4px 9px -3px rgba(102, 136, 153, 0.15);
  padding: 0 15px;
  height: 100%;
}
.mobile-navbar-logo img {
  height: 40px;
  width: 40px;
}
.mobile-navbar .ant-btn.mobile-navbar-toggle-button {
  padding: 0 10px;
}
.mobile-navbar-menu .ant-dropdown-menu-item {
  font-weight: 500;
  color: rgba(255, 255, 255, 0.75);
}
.mobile-navbar-menu .ant-dropdown-menu-item-divider {
  background: rgba(255, 255, 255, 0.5);
}
.dashboard-header {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  position: -webkit-sticky;
  position: sticky;
  background: #f6f7f9;
  z-index: 99;
  width: 100%;
  top: 0;
  padding-top: 10px;
  margin-bottom: 10px;
}
.dashboard-header > div {
  padding: 5px 0;
}
.dashboard-header .title-with-tags {
  flex: 1 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: -5px 0;
}
.dashboard-header .title-with-tags > div {
  padding: 5px 0;
}
.dashboard-header .title-with-tags h3 {
  margin: 0;
}
@media (max-width: 767px) {
  .dashboard-header .title-with-tags h3 {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .dashboard-header {
    position: static;
  }
}
.dashboard-header .profile-image {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  margin: 3px 5px 0 5px;
}
.dashboard-header .tags-control > .label-tag {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
.dashboard-header:hover .tags-control > .label-tag,
.dashboard-header:focus .tags-control > .label-tag,
.dashboard-header:active .tags-control > .label-tag,
.dashboard-header:focus-within .tags-control > .label-tag {
  opacity: 1;
}
.dashboard-header .dashboard-control .icon-button {
  width: 32px;
  padding: 0 10px;
}
.dashboard-header .dashboard-control .save-status {
  vertical-align: middle;
  margin-right: 7px;
  font-size: 12px;
  text-align: left;
  display: inline-block;
}
.dashboard-header .dashboard-control .save-status[data-saving] {
  opacity: 0.6;
  width: 45px;
}
.dashboard-header .dashboard-control .save-status[data-saving]:after {
  content: "";
  animation: saving 2s linear infinite;
}
.dashboard-header .dashboard-control .save-status[data-error] {
  color: #f44336;
}
@media (max-width: 515px) {
  .dashboard-header .dashboard-control {
    flex-basis: 100%;
  }
}
@keyframes saving {
  0%,
  100% {
    content: ".";
  }
  33% {
    content: "..";
  }
  66% {
    content: "...";
  }
}
/* Queries editor, Ace */
.ace_editor {
  border: 1px solid rgba(102, 136, 153, 0.15);
  height: 100%;
  margin-bottom: 10px;
}
.ace_editor.ace_autocomplete .ace_completion-highlight {
  text-shadow: none !important;
  background: #ffff005e;
  font-weight: 600;
}
.ace_editor.ace-tm .ace_gutter {
  background: #fff !important;
}
.ace_editor.ace-tm .ace_gutter-active-line {
  background-color: rgba(102, 136, 153, 0.2) !important;
}
.ace_editor.ace-tm .ace_marker-layer .ace_active-line {
  background: rgba(102, 136, 153, 0.09) !important;
}
.ace-editor-input .ace_hidden-cursors {
  opacity: 0;
}
.ace-editor-input .ace_scrollbar {
  z-index: auto;
}
/* Query page, schema browser */
body.fixed-layout {
  padding: 0;
  overflow: hidden;
}
body.fixed-layout #application-root {
  display: flex;
  flex-direction: row;
  padding-bottom: 0;
  width: 100vw;
  height: 100vh;
}
.p-b-60 {
  padding-bottom: 60px !important;
}
.bottom-controller-container {
  box-shadow: 0 0 9px 0 rgba(102, 136, 153, 0.15);
  z-index: 1;
  border: none !important;
  flex-shrink: 0;
}
.filter-container {
  margin-bottom: 5px;
}
.schema-container {
  background: transparent;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.editor__left {
  height: 100% !important;
  width: calc(25% - 10px);
  margin-right: 10px;
}
.editor__left .form-control {
  height: 30px;
}
.query-alerts .alert {
  margin-bottom: 15px;
}
.query-log-line {
  font-family: monospace;
  white-space: pre;
  margin: 0;
}
.paginator-container {
  text-align: center;
}
.tile .paginator-container {
  text-align: center;
  margin-top: 10px;
}
.query__vis table {
  border: 1px solid #f0f0f0;
}
.query__vis .paginator-container {
  text-align: center;
  margin-top: 10px;
}
.query__vis .paginator-container li:first-of-type {
  margin-left: 0;
}
.embed__vis {
  display: flex;
  flex-flow: column;
  width: 100%;
}
.embed-heading h3 {
  line-height: 1.75;
  margin: 0;
}
.widget-wrapper .body-container .filters-wrapper {
  display: block;
  padding-left: 15px;
}
.query-fixed-layout .filters-wrapper {
  max-height: 40%;
  overflow: auto;
}
.page-header--new .query-tags .label-default,
.page-header--new .query-tags__mobile .label-default,
.page-header--new .query-tags .label-warning,
.page-header--new .query-tags__mobile .label-warning {
  margin-right: 3px;
}
.label-tag {
  background: rgba(102, 136, 153, 0.15);
  color: #475f6b;
}
.label-tag:hover,
.label-tag:focus,
.label-tag:active {
  color: #475f6b;
  background: rgba(102, 136, 153, 0.25);
}
.query-page-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}
.query-fullscreen {
  background: #fff;
  padding: 0;
  box-shadow: rgba(102, 136, 153, 0.15) 0 4px 9px -3px;
  flex-grow: 1;
  display: flex;
}
.query-fullscreen .resizable-component.react-resizable .react-resizable-handle-horizontal {
  border-right: 1px solid #efefef;
}
.query-fullscreen .resizable-component.react-resizable .react-resizable-handle-vertical {
  border-bottom: 1px solid #efefef;
}
.query-fullscreen .query-metadata.query-metadata-horizontal {
  border-bottom: 1px solid #efefef;
}
.query-fullscreen .tile,
.query-fullscreen .tiled {
  box-shadow: none;
  padding: 15px 0 !important;
}
.query-fullscreen nav {
  position: relative;
  display: flex;
  flex-flow: column;
  flex-basis: 25%;
  flex-shrink: 0;
  max-width: 600px;
  min-width: 10px;
  overflow-x: hidden;
}
.query-fullscreen nav .editor__left__data-source,
.query-fullscreen nav .schema-control,
.query-fullscreen nav .editor {
  flex-shrink: 0;
}
.query-fullscreen nav .editor__left__schema,
.query-fullscreen nav .editor__left__data-source {
  padding: 15px;
}
.query-fullscreen nav .editor__left__data-source .ant-select .ant-select-selection-selected-value img,
.query-fullscreen nav .editor__left__data-source .ant-select .ant-select-selection-selected-value span {
  vertical-align: middle;
}
.query-fullscreen nav .editor__left__schema {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
  padding-top: 0 !important;
  position: relative;
}
.query-fullscreen nav .editor__left__schema .schema-container {
  position: absolute;
  left: 15px;
  top: 0;
  right: 15px;
  bottom: 0;
}
.query-fullscreen .content {
  background: #fff;
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-content: space-around;
  padding: 0;
  overflow-x: hidden;
}
.query-fullscreen .row {
  background: #fff;
  min-height: 50px;
}
.query-fullscreen .row.resizable {
  flex: 0 0 300px;
}
.query-fullscreen .row.editor {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-content: space-around;
  overflow: hidden;
  min-height: 10px;
  max-height: 70vh;
  flex: 0 0 300px;
}
.query-fullscreen .row .row {
  display: block;
  min-height: 0;
}
.query-fullscreen section {
  box-sizing: border-box;
  flex: 1;
  min-width: 30px;
}
.query-fullscreen section.resizable {
  flex: 0 0 300px;
}
.query-fullscreen .resizable {
  position: relative;
}
.query-fullscreen .resizable.no-transition {
  transition: none !important;
}
.query-fullscreen .rg-right,
.query-fullscreen .rg-left,
.query-fullscreen .rg-top,
.query-fullscreen .rg-bottom {
  display: block;
  width: 10px;
  height: 10px;
  line-height: 15px;
  position: absolute;
  z-index: 99;
}
.query-fullscreen .rg-right span,
.query-fullscreen .rg-left span,
.query-fullscreen .rg-top span,
.query-fullscreen .rg-bottom span {
  position: absolute;
  box-sizing: border-box;
  display: block;
  border: 1px solid #ccc;
}
.query-fullscreen .rg-right span,
.query-fullscreen .rg-left span {
  border-width: 0 1px;
  top: 50%;
  margin: -10px 0 0 15px / 4;
  height: 20px;
  width: 3px;
}
.query-fullscreen .rg-top span,
.query-fullscreen .rg-bottom span {
  border-width: 1px 0;
  left: 50%;
  margin: 15px / 4 0 0 -10px;
  width: 20px;
  height: 3px;
}
.query-fullscreen .rg-top {
  cursor: row-resize;
  width: 100%;
  top: 0;
  left: 0;
  margin-top: -15px / 2;
}
.query-fullscreen .rg-right {
  cursor: col-resize;
  border-right: 1px solid #efefef;
  height: 100%;
  right: 0;
  top: 0;
  margin-right: 0px;
}
.query-fullscreen .rg-right:hover {
  background: rgba(102, 136, 153, 0.06);
}
.query-fullscreen .rg-bottom {
  cursor: row-resize;
  background: #fff;
  width: 100%;
  bottom: 0;
  left: 0;
  margin-bottom: 0;
}
.query-fullscreen .rg-bottom:hover {
  background: rgba(102, 136, 153, 0.06);
}
.query-fullscreen .rg-left {
  cursor: col-resize;
  height: 100%;
  left: 0;
  top: 0;
  margin-left: -15px;
}
.datasource-small {
  visibility: hidden;
}
.modal-xl .modal-content {
  border: none;
}
.visualization-editor .modal-title {
  font-weight: 600;
  font-size: 20px;
}
.visualization-editor .modal-body {
  bottom: 50px;
}
.visualization-editor .modal-footer {
  height: auto;
}
.visualization-editor .visualization-editor__right {
  margin-top: 23px;
  border: 1px solid #eee;
  border-radius: 3px;
}
.visualization-editor .visualization-editor__right .parameter-container {
  padding-left: 25px;
  margin-top: 10px;
}
nav .rg-bottom {
  visibility: hidden;
}
.query-tags {
  display: inline-block;
  vertical-align: middle;
}
.query-tags__mobile {
  display: none;
}
.table--permission .profile__image {
  margin-right: 0;
}
.mp__permission-type {
  text-transform: capitalize;
}
.edit-visualization {
  margin-right: 5px;
}
@media (min-width: 880px) {
  .query-fullscreen .query-metadata.query-metadata-horizontal {
    display: none;
  }
}
@media (max-width: 880px) {
  .btn--showhide,
  .query-actions-menu .dropdown-toggle {
    margin-bottom: 5px;
  }
  .btn-publish {
    display: none;
  }
  .query-fullscreen {
    flex-direction: column;
    overflow: hidden;
  }
  .query-fullscreen nav {
    display: none;
  }
  .query-fullscreen .schema-container {
    display: none;
  }
  .query-fullscreen main {
    flex-direction: column-reverse;
  }
  .query-fullscreen main nav {
    width: 100%;
    max-width: 100%;
    border-right: none;
  }
  .query-fullscreen main nav .editor__left__schema {
    height: 300px !important;
  }
  .query-fullscreen main nav .rg-right {
    display: none;
  }
  .query-fullscreen .content {
    width: 100%;
    height: 100%;
  }
  .query-fullscreen .content .static-position__mobile {
    position: static !important;
  }
  .query-fullscreen .bottom-controller-container {
    z-index: 9;
  }
  .datasource-small {
    visibility: visible;
  }
}
@media (max-width: 768px) {
  .editor__left__schema,
  .editor__left__data-source {
    display: none;
  }
  .filter-container {
    padding-right: 0;
  }
}
.schema-container {
  height: 100%;
  z-index: 10;
  background-color: white;
}
.schema-container .schema-browser {
  overflow: hidden;
  border: none;
  padding-top: 10px;
  position: relative;
  height: 100%;
}
.schema-container .schema-browser .schema-loading-state {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.schema-container .schema-browser .collapse.in {
  background: transparent;
}
.schema-container .schema-browser .copy-to-editor {
  visibility: hidden;
  color: rgba(102, 136, 153, 0.9);
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: none;
}
.schema-container .schema-browser .schema-list-item {
  display: flex;
  border-radius: 3px;
  height: 22px;
}
.schema-container .schema-browser .schema-list-item .table-name {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  padding: 2px 22px 2px 10px;
}
.schema-container .schema-browser .schema-list-item:hover,
.schema-container .schema-browser .schema-list-item:focus,
.schema-container .schema-browser .schema-list-item:focus-within {
  background: rgba(102, 136, 153, 0.1);
}
.schema-container .schema-browser .schema-list-item:hover .copy-to-editor,
.schema-container .schema-browser .schema-list-item:focus .copy-to-editor,
.schema-container .schema-browser .schema-list-item:focus-within .copy-to-editor {
  visibility: visible;
}
.schema-container .schema-browser .table-open .table-open-item {
  display: flex;
  height: 18px;
  width: calc(100% - 22px);
  padding-left: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: none;
}
.schema-container .schema-browser .table-open .table-open-item div:first-child {
  flex: 1;
}
.schema-container .schema-browser .table-open .table-open-item .column-type {
  color: rgba(118, 118, 118, 0.8);
  font-size: 10px;
  margin-left: 2px;
  text-transform: uppercase;
}
.schema-container .schema-browser .table-open .table-open-item:hover,
.schema-container .schema-browser .table-open .table-open-item:focus,
.schema-container .schema-browser .table-open .table-open-item:focus-within {
  background: rgba(102, 136, 153, 0.1);
}
.schema-container .schema-browser .table-open .table-open-item:hover .copy-to-editor,
.schema-container .schema-browser .table-open .table-open-item:focus .copy-to-editor,
.schema-container .schema-browser .table-open .table-open-item:focus-within .copy-to-editor {
  visibility: visible;
}
.schema-container .schema-control {
  display: flex;
  flex-wrap: nowrap;
  padding: 0;
}
.schema-container .schema-control .ant-btn {
  height: auto;
}
.schema-container .parameter-label {
  display: block;
}
.query-api-key-dialog-wrapper .ant-input-group.ant-input-group-compact {
  display: flex;
  flex-wrap: nowrap;
}
.query-api-key-dialog-wrapper .ant-input-group.ant-input-group-compact .ant-input {
  flex-grow: 1;
  flex-shrink: 1;
}
.query-api-key-dialog-wrapper .ant-input-group.ant-input-group-compact .ant-btn {
  flex-grow: 0;
  flex-shrink: 0;
  height: auto;
}
/* General components */
.resizable-component.react-resizable {
  position: relative;
}
.resizable-component.react-resizable .react-resizable-handle {
  position: absolute;
  background: #fff;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.resizable-component.react-resizable .react-resizable-handle:hover,
.resizable-component.react-resizable .react-resizable-handle:active {
  background: #f6f8f9;
}
.resizable-component.react-resizable .react-resizable-handle.react-resizable-handle-horizontal {
  cursor: col-resize;
  width: 10px;
  height: auto;
  right: 0;
  top: 0;
  bottom: 0;
}
.resizable-component.react-resizable .react-resizable-handle.react-resizable-handle-horizontal:before {
  content: "";
  display: inline-block;
  width: 3px;
  height: 25px;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}
.resizable-component.react-resizable .react-resizable-handle.react-resizable-handle-vertical {
  cursor: row-resize;
  width: auto;
  height: 10px;
  left: 0;
  right: 0;
  bottom: 0;
}
.resizable-component.react-resizable .react-resizable-handle.react-resizable-handle-vertical:before {
  content: "";
  display: inline-block;
  width: 25px;
  height: 3px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.table {
  margin-bottom: 0;
}
.table [class*="bg-"] > tr > th {
  color: #fff;
  border-bottom: 0;
  background: transparent !important;
}
.table [class*="bg-"] + tbody > tr:first-child > td {
  border-top: 0;
}
.table > thead > tr > th {
  vertical-align: middle;
  font-weight: 500;
  color: #333;
  border-width: 1px;
  text-transform: none;
  padding: 15px 10px;
}
.table > thead > tr > th:first-child,
.table > tbody > tr > th:first-child,
.table > tfoot > tr > th:first-child,
.table > thead > tr > td:first-child,
.table > tbody > tr > td:first-child,
.table > tfoot > tr > td:first-child {
  padding-left: 15px;
}
.table > thead > tr > th:last-child,
.table > tbody > tr > th:last-child,
.table > tfoot > tr > th:last-child,
.table > thead > tr > td:last-child,
.table > tbody > tr > td:last-child,
.table > tfoot > tr > td:last-child {
  padding-right: 15px;
}
.table tbody > tr:last-child > td {
  padding-bottom: 10px;
}
.table.table-condensed tbody > tr:last-child > td {
  padding-bottom: 7px;
}
.table-bordered {
  border: 0;
}
.table-bordered > tbody > tr > td,
.table-bordered > tbody > tr > th {
  border-bottom: 0;
  border-left: 0;
}
.table-bordered > tbody > tr > td:last-child,
.table-bordered > tbody > tr > th:last-child {
  border-right: 0;
}
.table-bordered > thead > tr > th {
  border-left: 0;
}
.table-bordered > thead > tr > th:last-child {
  border-right: 0;
}
.table-vmiddle td {
  vertical-align: middle !important;
}
.table-responsive {
  border: 0;
}
.tile .table > thead:not([class*="bg-"]) > tr > th {
  border-top: 1px solid #f0f0f0;
}
.table-hover > tbody > tr:hover {
  background-color: #fff !important;
  background-color: rgba(102, 136, 153, 0.05) !important;
}
.table:not(.table-striped) > thead > tr > th {
  background-color: #fff !important;
  background-color: rgba(102, 136, 153, 0.03) !important;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  vertical-align: middle;
}
.table-condensed > tbody > tr > td {
  padding: 7px 10px;
}
.table-border {
  border: 1px solid #f0f0f0;
}
.textbox-dialog small {
  display: block;
  margin-top: 4px;
}
.textbox-dialog .preview {
  padding: 9px 9px 1px;
  background-color: #f7f7f7;
  margin-top: 8px;
  word-wrap: break-word;
}
.textbox-dialog .preview-title {
  display: block;
  margin-top: -5px;
}
.widget-wrapper .widget-actions {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
.widget-wrapper .widget-actions .action {
  font-size: 24px;
  cursor: pointer;
  line-height: 100%;
  display: block;
  padding: 4px 10px 3px;
}
.widget-wrapper .widget-actions .action:focus {
  background-color: rgba(0, 0, 0, 0.1);
}
.widget-wrapper .widget-actions .action:hover {
  background-color: transparent;
  color: #2196F3;
}
.widget-wrapper .widget-actions .action:active {
  filter: brightness(75%);
}
.widget-wrapper .parameter-container {
  margin: 0 15px;
}
.widget-wrapper .body-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.widget-wrapper .body-container .body-row {
  flex: 0 1 auto;
}
.widget-wrapper .body-container .body-row-auto {
  flex: 1 1 auto;
}
.widget-wrapper .spinner-container {
  position: relative;
}
.widget-wrapper .spinner-container .spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.widget-wrapper .scrollbox:empty {
  padding: 0 !important;
  font-size: 1px !important;
}
.widget-wrapper .widget-text :first-child {
  margin-top: 0;
}
.widget-wrapper .widget-text :last-child {
  margin-bottom: 0;
}
.editing-mode .widget-menu-remove {
  display: block;
}
.editing-mode .query-link {
  pointer-events: none;
  cursor: move;
}
.editing-mode .th-title {
  cursor: move;
}
.editing-mode .refresh-indicator {
  transition-duration: 0s;
  font-size: 13px;
  transition-delay: 0s;
  color: #bbbbbb;
  transform: translateY(-4px);
}
.editing-mode .refresh-indicator .rd-timer {
  display: none;
}
.editing-mode .refresh-indicator .refresh-icon:before {
  transition-delay: 0s;
  opacity: 0;
}
.editing-mode .refresh-indicator .rd-timer {
  transition-delay: 0s;
  opacity: 1;
  transform: translateX(0);
}
.refresh-indicator {
  font-size: 18px;
  color: #86a1af;
  transition: all 100ms linear;
  transition-delay: 150ms;
  transform: translateX(22px);
  position: absolute;
  right: 29px;
  top: 8px;
  display: flex;
  flex-direction: row-reverse;
}
.refresh-indicator .refresh-icon {
  position: relative;
}
.refresh-indicator .refresh-icon:before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0;
  width: 24px;
  height: 24px;
  background-color: #e8ecf0;
  border-radius: 50%;
  transition: opacity 100ms linear;
  transition-delay: 150ms;
}
.refresh-indicator .refresh-icon i {
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.refresh-indicator .rd-timer {
  font-size: 13px;
  display: inline-block;
  font-variant-numeric: tabular-nums;
  opacity: 0;
  transform: translateX(-6px);
  transition: all 100ms linear;
  transition-delay: 150ms;
  color: #bbbbbb;
  background-color: rgba(255, 255, 255, 0.9);
  padding-left: 2px;
  padding-right: 1px;
  margin-right: -4px;
  margin-top: 2px;
}
.widget-visualization[data-refreshing="false"] .refresh-indicator {
  display: none;
}
.tile .widget-menu-regular,
.tile .btn__refresh {
  opacity: 0 !important;
  transition: opacity 0.35s ease-in-out;
}
.tile .t-header .th-title {
  padding-right: 23px;
}
.tile .t-header .th-title .hidden-print {
  margin-bottom: 0;
}
.tile .t-header .th-title .query-link {
  color: rgba(0, 0, 0, 0.8);
  font-size: 15px;
  font-weight: 500;
}
.tile .t-header .th-title .query-link:not(.visualization-name) {
  color: rgba(0, 0, 0, 0.5);
}
.tile .t-header .query--description {
  font-size: 14px;
  line-height: 1.5;
  font-style: italic;
}
.tile .t-header .query--description p {
  margin-bottom: 0;
}
.tile .t-header.widget {
  padding: 15px;
}
.tile:hover .widget-menu-regular,
.tile:focus .widget-menu-regular,
.tile:active .widget-menu-regular,
.tile:focus-within .widget-menu-regular,
.tile:hover .btn__refresh,
.tile:focus .btn__refresh,
.tile:active .btn__refresh,
.tile:focus-within .btn__refresh {
  opacity: 1 !important;
  transition: opacity 0.35s ease-in-out;
}
.tile:hover .refresh-indicator,
.tile:focus .refresh-indicator,
.tile:active .refresh-indicator,
.tile:focus-within .refresh-indicator {
  font-size: 13px;
  transition-delay: 0s;
  color: #bbbbbb;
  transform: translateY(-4px);
}
.tile:hover .refresh-indicator .refresh-icon:before,
.tile:focus .refresh-indicator .refresh-icon:before,
.tile:active .refresh-indicator .refresh-icon:before,
.tile:focus-within .refresh-indicator .refresh-icon:before {
  transition-delay: 0s;
  opacity: 0;
}
.tile:hover .refresh-indicator .rd-timer,
.tile:focus .refresh-indicator .rd-timer,
.tile:active .refresh-indicator .rd-timer,
.tile:focus-within .refresh-indicator .rd-timer {
  transition-delay: 0s;
  opacity: 1;
  transform: translateX(0);
}
.tile .tile__bottom-control {
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tile .tile__bottom-control .btn-transparent:first-child {
  margin-left: -10px;
}
.tile .tile__bottom-control .btn-transparent:last-child {
  margin-right: -10px;
}
.tile .tile__bottom-control a,
.tile .tile__bottom-control .plain-button {
  color: rgba(0, 0, 0, 0.65);
}
.tile .tile__bottom-control a:hover,
.tile .tile__bottom-control .plain-button:hover,
.tile .tile__bottom-control a:focus,
.tile .tile__bottom-control .plain-button:focus {
  color: rgba(0, 0, 0, 0.95);
}
