a.visualization-editor-context-help {
  &,
  .ant-typography & {
    font: inherit;
    color: inherit;

    &:hover,
    &:active {
      color: #0a6ebd;
    }
  }
}

.context-help-default-icon {
  margin-left: 5px;
  margin-right: 5px;
}
