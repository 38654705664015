/* Sankey Visualization */
.sankey .node rect {
  fill-opacity: .9;
  shape-rendering: crispEdges;
  stroke-width: 0;
}
.sankey .node text {
  text-shadow: 0 1px 0 #fff;
}
.sankey .link {
  fill: none;
  stroke: #000;
  stroke-opacity: .2;
}

.sankey-visualization-container {
  height: 500px;
  overflow: hidden;
}
