.query-api-key-dialog-wrapper {
  .ant-input-group.ant-input-group-compact {
    display: flex;
    flex-wrap: nowrap;

    .ant-input {
      flex-grow: 1;
      flex-shrink: 1;
    }

    .ant-btn {
      flex-grow: 0;
      flex-shrink: 0;
      height: auto;
    }
  }
}
