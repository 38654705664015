.table-visualization-editor-columns {
  .ant-collapse {
    background: transparent;
  }

  .ant-collapse-item {
    background: #ffffff;

    .drag-handle {
      height: 20px;
      margin-left: -16px;
      padding: 0 16px;
    }
  }

  .table-editor-columns-dragged-item {
    z-index: 1;
  }
}

.table-visualization-editor-column {
  padding-left: 6px;

  .image-dimension-selector {
    display: flex;
    align-items: center;

    .image-dimension-selector-spacer {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}
