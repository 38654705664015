.details-viz {
  .ant-descriptions-item-label {
    width: 1px;
    white-space: nowrap;
  }

  .paginator-container {
    margin-top: 10px;
  }
}
