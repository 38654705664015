@import "../variables";

.table-visualization-container {
  .ant-pagination.ant-table-pagination {
    float: none;
    display: block;
    text-align: center;
    margin-bottom: 0;
  }

  .ant-table {
    overflow-x: auto;
  }

  .table-fixed-header {
    table {
      border-top: 0;

      th:not(.table-visualization-search) {
        position: sticky !important;
        left: 0;
        top: 0;
        border-top: 0;
        z-index: 1;
        background: #fafafa !important;
      }
    }
  }

  table {
    border-top: @border-width-base @border-style-base @border-color-split;
    border-bottom: 0;

    .display-as-number,
    .display-as-boolean,
    .display-as-datetime,
    .display-as-image {
      width: 1%;
      white-space: nowrap;
    }

    .table-visualization-spacer {
      padding-left: 0;
      padding-right: 0;

      & > div:before {
        content: none !important;
      }
    }

    tbody tr:last-child td {
      border-bottom: 0;
    }

    thead {
      .ant-table-column-sorter-up,
      .ant-table-column-sorter-down {
        opacity: 0;
        transition: opacity 0.3s;
      }

      &:hover,
      .table-visualization-column-is-sorted {
        .ant-table-column-sorter-up,
        .ant-table-column-sorter-down {
          opacity: 1;
        }
      }

      th {
        white-space: nowrap;

        &.table-visualization-search {
          padding-bottom: 0;

          .ant-table-header-column {
            display: block;
          }
        }

        .ant-input-search {
          font-weight: normal;

          .ant-input-suffix .anticon {
            cursor: auto;
          }
        }

        // optimize room for th content
        &.ant-table-column-has-actions.ant-table-column-has-sorters {
          padding-right: 3px;
        }

        .table-visualization-heading {
          display: inline-block;
          max-width: 200px;
          vertical-align: middle;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &[data-sort-column-index]:before {
            @size: 12px;

            content: attr(data-sort-column-index);
            display: inline-block;
            vertical-align: middle;
            min-width: @size;
            height: @size;
            font-size: @size * 3/4;
            border-radius: @size / 2;
            background: #c0c0c0;
            text-align: center;
            line-height: @size;
            color: #fff;
            padding: 0 @size * 1/4;
            margin: 0 5px 0 0;
          }
        }
      }
    }
  }

  /* START table x scroll */
  // .dashboard-widget-wrapper:not(.widget-auto-height-enabled) &,
  // .query-fixed-layout & {
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   width: 100%;
  //   height: 100%;

  //   & div {
  //     min-height: 10rem;
  //     // height: inherit;
  //   }

  //   .ant-spin-container {
  //     display: flex;
  //     flex-direction: column;

  //     .ant-table {
  //       flex-grow: 1;
  //     }
  //   }
  // }
}

.table-visualization-search-info-content {
  max-width: 400px;
}

.table-visualization-search-info-icon {
  margin: 0 5px 0 0;

  &:not(:hover):not(:active) {
    color: @text-color-secondary;
  }
}
