.error-message-container {
  width: 100%;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .error-state {
    max-width: 1200px;
    width: 100%;

    @media (min-width: 768px) {
      width: 65%;
    }
  }
}
