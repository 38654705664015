.visualization-editor-control-label {
  &.visualization-editor-control-label-horizontal {
    label {
      margin-bottom: 0;
    }
  }
}

.visualization-editor-input {
  width: 100% !important;
}
