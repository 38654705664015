@import "../../visualizations/variables";

@jvi-gutter: 20px;
@jvi-spacing: 2px;

.jvi-root {
  display: block;
  font-family: @font-family-monospace;
}

.jvi-block {
  display: block;
  border-left: 1px dotted @table-border-color;
  margin: 0 0 0 2px;
}

.jvi-item {
  display: block;
  position: relative;
  padding: 0 0 0 @jvi-gutter;
  white-space: nowrap;

  .jvi-item {
    margin: @jvi-spacing 0;
  }

  &.jvi-nested-last > span > .jvi-punctuation.jvi-comma {
    display: none;
  }
}

.jvi-toggle {
  position: absolute;
  left: 0;
  top: 0;
  width: @jvi-gutter;
  height: @jvi-gutter;
  line-height: @jvi-gutter;
  text-align: center;
  cursor: pointer;
  z-index: 1;
  color: @text-color;
  opacity: 0.5;

  &:hover {
    opacity: 0.8;
  }

  i {
    vertical-align: middle;
  }

  &.hidden {
    display: none;
  }
}

.jvi-punctuation {
  color: @text-color;

  &.jvi-string {
    color: @green;
  }

  &.jvi-braces {
    margin: 0 @jvi-spacing;
  }

  &.jvi-ellipsis {
    padding: 0 @jvi-spacing;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &.hidden {
    display: none;
  }
}

.jvi-value {
  color: @green;

  &.jvi-primitive {
    color: @orange;
  }

  &.jvi-string {
    white-space: normal;
  }
}

.jvi-object-key {
  .jvi-value,
  .jvi-punctuation {
    color: @blue;
  }
}

.jvi-comment {
  color: @text-muted;
  font-family: @visualizations-font;
  font-style: italic;
  margin: 0 0 0 2 * @jvi-spacing;
  opacity: 0.5;

  &.hidden {
    display: none;
  }
}
