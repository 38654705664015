.schema-container {
  height: 100%;
  z-index: 10;
  background-color: white;

  .schema-browser {
    overflow: hidden;
    border: none;
    padding-top: 10px;
    position: relative;
    height: 100%;

    .schema-loading-state {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    .collapse.in {
      background: transparent;
    }

    .copy-to-editor {
      visibility: hidden;
      color: fade(@redash-gray, 90%);
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: none;
    }

    .schema-list-item {
      display: flex;
      border-radius: @redash-radius;
      height: 22px;

      .table-name {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
        padding: 2px 22px 2px 10px;
      }

      &:hover,
      &:focus,
      &:focus-within {
        background: fade(@redash-gray, 10%);

        .copy-to-editor {
          visibility: visible;
        }
      }
    }

    .table-open {
      .table-open-item {
        display: flex;
        height: 18px;
        width: calc(100% - 22px);
        padding-left: 22px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        transition: none;

        div:first-child {
          flex: 1;
        }

        .column-type {
          color: fade(@text-color, 80%);
          font-size: 10px;
          margin-left: 2px;
          text-transform: uppercase;
        }

        &:hover,
        &:focus,
        &:focus-within {
          background: fade(@redash-gray, 10%);

          .copy-to-editor {
            visibility: visible;
          }
        }
      }
    }
  }

  .schema-control {
    display: flex;
    flex-wrap: nowrap;
    padding: 0;

    .ant-btn {
      height: auto;
    }
  }

  .parameter-label {
    display: block;
  }
}
