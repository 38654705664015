.query-fullscreen {
  .query-editor-wrapper {
    padding: 15px;
    margin-bottom: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    .query-editor-container {
      flex: 1 1 auto;

      &[data-executing] {
        .ace_marker-layer {
          .ace_selection {
            background-color: rgb(255, 210, 181);
          }
        }
      }
    }

    .query-editor-controls {
      flex: 0 0 auto;
      margin-top: 10px;
    }
  }

  .query-page-query-description {
    border-top: 1px solid #efefef;
    padding: 0 15px 0 0;

    .edit-in-place {
      display: block;
      max-height: 150px;
      overflow: auto;
      padding: 15px 5px 15px 15px;

      &.active {
        overflow: visible;
        .ant-input {
          resize: vertical;
          max-height: 150px - 15px * 2;
        }
      }
    }
  }
}
