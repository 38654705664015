.funnel-visualization-container {
  table {
    min-width: 450px;
    table-layout: fixed;

    tbody tr td {
      border: none;

      &.text-ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .step {
    max-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .step .step-name {
    visibility: hidden;
    width: inherit;
    padding: 3px 5px;
    background-color: white;
    border: 1px solid;
    border-radius: 3px;
    position: absolute;
    z-index: 1;
    white-space: initial;
    word-wrap: break-word;
  }

  .step:hover .step-name {
    visibility: visible;
  }
}
