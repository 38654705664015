.textbox-dialog {
  small {
    display: block;
    margin-top: 4px;
  }

  .preview {
    padding: 9px 9px 1px;
    background-color: #f7f7f7;
    margin-top: 8px;
    word-wrap: break-word
  }

  .preview-title {
    display: block;
    margin-top: -5px;
  }
}