.color-picker-input-swatches {
  margin: 0 0 10px 0;
  text-align: left;
  white-space: nowrap;

  .color-swatch {
    cursor: pointer;
    margin: 0 10px 0 0;

    &:last-child {
      margin-right: 0;
    }
  }
}

.color-picker-input {
  text-align: left;
  white-space: nowrap;
}
