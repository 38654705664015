.profile__image {
  float: left;
  margin-right: 10px;
  border-radius: 100%;
}

.profile__h3 {
  margin: 8px 0 0 0;
}

.profile__container {
  .well {
    .form-group:last-of-type {
      margin-bottom: 0;
    }
  }
}

.profile__dl {
    dd {
        margin-bottom: 12px;
    }
}

.alert-invited {
  .form-control {
    cursor: text !important;
    background: #fff !important;
  }
}
