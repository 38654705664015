@desktopNavbarItemHeight: 3rem;
@desktopNavbarLogoPadding: 1rem;
@desktopNavbarLogoHeight: 3.6rem;
@desktopNavbarItemTextPadding: .5rem;

.desktop-navbar {
  height: 100vh;
  position: relative;

  .desktop-navbar-main-menu {
    height: 100%;
  }

  .desktop-navbar-profile-menu {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .ant-menu-item.ant-menu-item-only-child {
    display: block;
    padding: @desktopNavbarLogoPadding;
    height: @desktopNavbarLogoHeight + 2*@desktopNavbarLogoPadding;
    text-align: center;
    img {
      max-height: @desktopNavbarLogoHeight;
    }
    .ant-menu-title-content {
      padding-left: 0;
    }
  }

  .ant-menu-item {
    height: @desktopNavbarItemHeight;
  }

  .ant-menu-item,
  .ant-menu-submenu {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    .ant-menu-title-content {
      padding-left: @desktopNavbarItemTextPadding;
    }
  }

  .ant-menu-submenu-title {
    background-color: rgba(255, 255, 255, 0.05);
    font-weight: 500;
  }

  .ant-menu-item-divider {
    background-color: rgba(255, 255, 255, 0.15);
    margin: 0.5rem 1rem;
  }
}
