.query-visualization-tabs {
  .query-results-empty-state {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 15px;
    text-align: center;
  }

  .ant-tabs-nav-wrap,
  .ant-tabs-extra-content {
    flex: initial !important;
  }

  .ant-tabs-nav-wrap {
    z-index: 1;
  }

  .ant-tabs-tab {
    background: #f6f8f9 !important;
    border-color: #d9d9d9 !important;
    border-bottom: 0px !important;
    border-radius: 0 !important;
    // border-width animation makes it flicker on Firefox
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-width 0s !important;

    &:first-child {
      border-radius: 2px 0 0 0 !important;
    }

    &:last-child {
      border-radius: 0 2px 0 0 !important;
    }

    &:not(:first-child) {
      margin-left: -1px !important;
    }

    &.ant-tabs-tab-active {
      background: white !important;
      font-weight: normal;
      border-top: 2px solid #2196f3 !important;

      .ant-tabs-tab-btn {
        font-weight: normal;
      }
    }

    // add internal bottom border to non-active tabs
    &:not(.ant-tabs-tab-active) {
      box-shadow: 0px -1px 0px #d9d9d9 inset;
    }
  }

  .ant-tabs-content-holder {
    margin-top: -17px;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 0px 4px 0px 0px;
    // .ant-tabs-tabpane {
    //   padding: 16px;
    //   background: white;
    // }
  }

  .add-visualization-button {
    span {
      color: #767676;
    }
  }

  .delete-visualization-button {
    height: 1.5rem;
    width: 1.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    color: #a09797;
    font-size: 11px;
    border-radius: 100%;

    &:hover,
    &:focus {
      color: white;
      background-color: #ff8080;
    }

    &:active {
      filter: brightness(80%);
    }
  }
}

// hide delete button when it in the dropdown
.ant-tabs-dropdown-menu-item .delete-visualization-button {
  display: none;
}

.query-fixed-layout .query-visualization-tabs .visualization-renderer {
  padding: 15px;
}
