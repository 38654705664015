.dashboard-wrapper {
  flex-grow: 1;
  margin-bottom: 70px;

  .layout {
    margin: -15px -15px 0;
  }

  .tile {
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }

  .pivot-table-visualization-container > table,
  .visualization-renderer > .visualization-renderer-wrapper {
    overflow: visible;
  }

  &.preview-mode {
    .widget-menu-regular {
      display: block;
    }
    .widget-menu-remove {
      display: none;
    }
  }

  &.editing-mode {
    /* Y axis lines */
    background: linear-gradient(to right, transparent, transparent 1px, #f6f8f9 1px, #f6f8f9),
      linear-gradient(to bottom, #b3babf, #b3babf 1px, transparent 1px, transparent);
    background-size: 5px 50px;
    background-position-y: -8px;

    /* X axis lines */
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 85px;
      right: 0;
      background: linear-gradient(to bottom, transparent, transparent 2px, #f6f8f9 2px, #f6f8f9 5px),
        linear-gradient(to left, #b3babf, #b3babf 1px, transparent 1px, transparent);
      background-size: calc((100% + 15px) / 6) 5px;
      background-position: -7px 1px;
    }
  }

  .widget-auto-height-enabled {
    .spinner {
      position: static;
    }

    .scrollbox {
      overflow-y: hidden;
    }
  }
}

.react-grid-layout {
  &.disable-animations {
    & > .react-grid-item {
      transition: none !important;
    }
  }
}

.dashboard-wrapper .dashboard-widget-wrapper:not(.widget-auto-height-enabled),
.query-fixed-layout {
  // .visualization-renderer {
  //   display: flex;
  //   flex-direction: column;
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   right: 0;
  //   bottom: 0;

  //   > .visualization-renderer-wrapper {
  //     flex-grow: 1;
  //     position: relative;
  //   }

  //   > .filters-wrapper {
  //     flex-grow: 0;
  //     flex-shrink: 0;
  //   }
  // }

  .sunburst-visualization-container,
  .sankey-visualization-container,
  .map-visualization-container,
  .word-cloud-visualization-container,
  .box-plot-deprecated-visualization-container,
  .chart-visualization-container {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    overflow: hidden;
  }

  .counter-visualization-container {
    height: 100%;

    .counter-visualization-content {
      position: absolute;
      left: 10px;
      top: 15px;
      right: 10px;
      bottom: 15px;
      height: auto;
      overflow: hidden;
      padding: 0;
    }
  }
}

.query-fixed-layout {
  .visualization-renderer > .visualization-renderer-wrapper {
    .counter-visualization-container {
      // counter is too large on Query pages, so let's add some constraints
      max-width: 600px;
      max-height: 400px;
      // center it
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
  }
}

// react-grid-layout overrides
.react-grid-item {
  touch-action: initial !important; // react-draggable disables touch by default

  &.react-draggable {
    touch-action: none !important;
  }

  // placeholder color
  &.react-grid-placeholder {
    border-radius: 3px;
    background-color: #e0e6eb;
    opacity: 0.5;
  }

  // resize placeholder behind widget, the lib's default is above 🤷‍♂️
  &.resizing {
    z-index: 3;
  }

  // auto-height animation
  &.cssTransforms:not(.resizing) {
    transition-property: transform, height; // added ", height"
  }

  // resize handle size
  & > .react-resizable-handle {
    background: none;
    &:after {
      width: 11px;
      height: 11px;
      right: 5px;
      bottom: 5px;
    }
  }
}
