.public-dashboard-page {
  width: 100%;

  .page-header-wrapper {
    margin-top: 0;
    margin-left: 15px;
    margin-right: 15px;
  }

  > .container {
    min-height: calc(100vh - 95px);
  }

  .loading-message {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #footer {
    height: 95px;
    text-align: center;
  }
}
