body.fixed-layout {
  padding: 0;
  overflow: hidden;

  #application-root {
    display: flex;
    flex-direction: row;
    padding-bottom: 0;

    width: 100vw;
    height: 100vh;
  }
}

.p-b-60 {
  padding-bottom: 60px !important;
}

.bottom-controller-container {
  box-shadow: 0 0 9px 0 rgba(102, 136, 153, 0.15);
  z-index: 1;
  border: none !important;
  flex-shrink: 0;
}

// Editor
.filter-container {
  margin-bottom: 5px;
}

.schema-container {
  background: transparent;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.editor__left {
  height: 100% !important;
  width: calc(~"25% - 10px");
  margin-right: 10px;

  .form-control {
    height: 30px;
  }
}

.query-alerts {
  .alert {
    margin-bottom: 15px;
  }
}

.query-log-line {
  font-family: monospace;
  white-space: pre;
  margin: 0;
}

.paginator-container {
  text-align: center;
}

.tile {
  .paginator-container {
    text-align: center;
    margin-top: 10px;
  }
}

.query__vis {
  table {
    border: 1px solid #f0f0f0;
  }

  .paginator-container {
    text-align: center;
    margin-top: 10px;

    li:first-of-type {
      margin-left: 0;
    }
  }
}

.embed__vis {
  display: flex;
  flex-flow: column;
  width: 100%;
}

.embed-heading {
  h3 {
    line-height: 1.75;
    margin: 0;
  }
}

.widget-wrapper {
  .body-container {
    .filters-wrapper {
      display: block;
      padding-left: 15px;
    }
  }
}

// Don't let filters take all visualization space on query fixed layout
.query-fixed-layout {
  .filters-wrapper {
    max-height: 40%;
    overflow: auto;
  }
}

.page-header--new {
  .query-tags,
  .query-tags__mobile {
    .label-default,
    .label-warning {
      margin-right: 3px;
    }
  }
}

.label-tag {
  background: fade(@redash-gray, 15%);
  color: darken(@redash-gray, 15%);

  &:hover,
  &:focus,
  &:active {
    color: darken(@redash-gray, 15%);
    background: fade(@redash-gray, 25%);
  }
}

.query-page-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.query-fullscreen {
  background: #fff;
  padding: 0;
  box-shadow: rgba(102, 136, 153, 0.15) 0 4px 9px -3px;
  flex-grow: 1;
  display: flex;

  .resizable-component.react-resizable {
    .react-resizable-handle-horizontal {
      border-right: 1px solid #efefef;
    }

    .react-resizable-handle-vertical {
      border-bottom: 1px solid #efefef;
    }
  }

  .query-metadata.query-metadata-horizontal {
    border-bottom: 1px solid #efefef;
  }

  .tile,
  .tiled {
    box-shadow: none;
    padding: 15px 0 !important;
  }

  nav {
    position: relative;
    display: flex;
    flex-flow: column;
    flex-basis: 25%;
    flex-shrink: 0;
    max-width: 600px;
    min-width: 10px;
    overflow-x: hidden;

    .editor__left__data-source,
    .schema-control,
    .editor {
      flex-shrink: 0;
    }

    .editor__left__schema,
    .editor__left__data-source {
      padding: 15px;
    }

    .editor__left__data-source {
      .ant-select {
        .ant-select-selection-selected-value {
          img,
          span {
            vertical-align: middle;
          }
        }
      }
    }

    .editor__left__schema {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      padding-bottom: 0;
      padding-top: 0 !important;
      position: relative;

      .schema-container {
        position: absolute;
        left: 15px;
        top: 0;
        right: 15px;
        bottom: 0;
      }
    }
  }
  .content {
    background: #fff;
    flex-grow: 1;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-around;
    align-content: space-around;
    padding: 0;
    overflow-x: hidden;
  }
  .row {
    background: #fff;
    min-height: 50px;

    &.resizable {
      flex: 0 0 300px;
    }

    &.editor {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-around;
      align-content: space-around;
      overflow: hidden;

      min-height: 10px;
      max-height: 70vh;
      flex: 0 0 300px;
    }

    .row {
      display: block;
      min-height: 0;
    }
  }

  section {
    box-sizing: border-box;
    flex: 1;
    min-width: 30px;
    &.resizable {
      flex: 0 0 300px;
    }
  }

  // **********************************************************************
  // directive styles
  // **********************************************************************
  .resizable {
    position: relative;
    &.no-transition {
      transition: none !important;
    }
  }
  .rg-right,
  .rg-left,
  .rg-top,
  .rg-bottom {
    display: block;
    width: 10px;
    height: 10px;
    line-height: @spacing;
    position: absolute;
    z-index: 99;

    span {
      position: absolute;
      box-sizing: border-box;
      display: block;
      border: 1px solid #ccc;
    }
  }
  .rg-right,
  .rg-left {
    span {
      border-width: 0 1px;
      top: 50%;
      margin: -10px 0 0 @spacing / 4;
      height: 20px;
      width: 3px;
    }
  }
  .rg-top,
  .rg-bottom {
    span {
      border-width: 1px 0;
      left: 50%;
      margin: @spacing / 4 0 0 -10px;
      width: 20px;
      height: 3px;
    }
  }
  .rg-top {
    cursor: row-resize;
    width: 100%;
    top: 0;
    left: 0;
    margin-top: -@spacing / 2;
  }
  .rg-right {
    cursor: col-resize;
    border-right: 1px solid #efefef;
    height: 100%;
    right: 0;
    top: 0;
    margin-right: 0px;

    &:hover {
      background: fade(@redash-gray, 6%);
    }
  }
  .rg-bottom {
    cursor: row-resize;
    background: #fff;
    width: 100%;
    bottom: 0;
    left: 0;
    margin-bottom: 0;

    &:hover {
      background: fade(@redash-gray, 6%);
    }
  }
  .rg-left {
    cursor: col-resize;
    height: 100%;
    left: 0;
    top: 0;
    margin-left: -@spacing;
  }
}

.datasource-small {
  visibility: hidden;
}

// Visualization editor
.modal-xl .modal-content {
  border: none;
}

.visualization-editor {
  .modal-title {
    font-weight: 600;
    font-size: 20px;
  }

  .modal-body {
    bottom: 50px;
  }

  .modal-footer {
    height: auto;
  }

  .visualization-editor__right {
    margin-top: 23px;
    border: 1px solid #eee;
    border-radius: 3px;

    .parameter-container {
      padding-left: 25px;
      margin-top: 10px;
    }
  }
}

// Left nav fixes for filling all the space
nav .rg-bottom {
  visibility: hidden;
}

.query-tags {
  display: inline-block;
  vertical-align: middle;
}

.query-tags__mobile {
  display: none;
}

.table--permission {
  .profile__image {
    margin-right: 0;
  }
}

.mp__permission-type {
  text-transform: capitalize;
}

.edit-visualization {
  margin-right: 5px;
}

@media (min-width: 880px) {
  .query-fullscreen {
    .query-metadata.query-metadata-horizontal {
      display: none;
    }
  }
}

// Smaller screens

@media (max-width: 880px) {
  .btn--showhide,
  .query-actions-menu .dropdown-toggle {
    margin-bottom: 5px;
  }

  .btn-publish {
    display: none;
  }

  .query-fullscreen {
    flex-direction: column;
    overflow: hidden;

    nav {
      display: none;
    }

    .schema-container {
      display: none;
    }

    main {
      flex-direction: column-reverse;

      nav {
        width: 100%;
        max-width: 100%;
        border-right: none;

        .editor__left__schema {
          height: 300px !important;
        }

        .rg-right {
          display: none;
        }
      }
    }

    .content {
      width: 100%;
      height: 100%;

      .static-position__mobile {
        position: static !important;
      }
    }

    .bottom-controller-container {
      z-index: 9;
    }
  }

  .datasource-small {
    visibility: visible;
  }
}

@media (max-width: 768px) {
  .editor__left__schema,
  .editor__left__data-source {
    display: none;
  }

  .filter-container {
    padding-right: 0;
  }
}
