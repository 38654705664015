*,
button,
input,
i,
a {
  -webkit-font-smoothing: antialiased;
}

*,
*:active,
*:hover {
  outline: none !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}

html {
  overflow-x: ~"hidden\0/";
  -ms-overflow-style: auto;
}

html,
body {
  min-height: 100vh;
}

body {
  padding-top: 0;
  margin: 0;
  background: #f6f8f9;
  font-family: @font-family-default;
  position: relative;

  #application-root {
    padding-bottom: 15px;
  }
}

iframe {
  border: 1px dashed @gray-lighter;
  padding: .5rem;
}

#application-root {
  min-height: 100vh;
}

#application-root,
#app-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

strong {
  font-weight: 500;
}

#content {
  position: relative;
  padding-top: 30px;
  padding-bottom: 30px;

  @media (min-width: (@screen-sm-min + 1)) {
    padding-right: 15px;
    padding-left: 15px;
  }

  @media (min-width: (@screen-lg-min + 80px)) {
    margin-left: @sidebar-left-width;
  }

  @media (min-width: @screen-sm-min) and (max-width: (@screen-md-max + 80px)) {
    margin-left: @sidebar-left-mid-width;
  }

  @media (max-width: (@screen-sm-min)) {
    margin-left: 0;
  }
}

.container {
  &.c-boxed {
    max-width: @boxed-width;
  }
}

.settings-screen,
.home-page,
.page-dashboard-list,
.page-queries-list,
.page-alerts-list,
.alert-page,
.admin-page-layout {
  .container {
    display: block;
    padding: 1.5rem;
  }
}

.scrollbox {
  overflow: auto;
  position: relative;
}

.clickable {
  cursor: pointer;

  button&:disabled {
    cursor: not-allowed;
  }
}

.resize-vertical {
  resize: vertical !important;
  transition: height 0s !important;
}
.resize-horizontal {
  resize: horizontal !important;
  transition: width 0s !important;
}
.resize-both,
.resize-vertical.resize-horizontal {
  resize: both !important;
  transition: height 0s, width 0s !important;
}

.bg-ace {
  background-color: fade(@redash-gray, 12%) !important;
}

// resizeable
.rg-top span,
.rg-bottom span {
  height: 3px;
  border-color: #b1c1ce; // TODO: variable
}

.rg-bottom {
  bottom: 15px;

  span {
    margin: 1.5px 0 0 -10px;
  }
}

// Plotly
text.slicetext {
  text-shadow: 1px 1px 5px #333;
}

// markdown
.markdown strong {
  font-weight: bold;
}

.markdown img {
  max-width: 100%;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  background-color: fade(@redash-gray, 15%);
  color: #111;
}

.profile__image--sidebar {
  border-radius: 100%;
  margin-right: 3px;
  margin-top: -2px;
}

.profile__image--settings {
  border-radius: 100%;
}

.profile__image_thumb {
  border-radius: 100%;
  margin-right: 3px;
  margin-top: -2px;
  width: 20px;
  height: 20px;
}

// Error state
.error-state {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  margin-top: 25vh;
  padding: 35px;
  font-size: 14px;
  line-height: 21px;

  .error-state__icon {
    .zmdi {
      font-size: 64px;
      color: @redash-gray;
    }
  }

  @media (max-width: 767px) {
    margin-top: 10vh;
  }
}

.warning-icon-danger {
  color: @red !important;
}

// page
.page-title {
  display: flex;
  align-items: center;

  .label {
    margin-top: 3px;
    display: inline-block;
  }

  .favorites-control {
    font-size: 19px;
    margin-right: 10px;
  }
}

.page-header--new {
  h3 {
    margin: 0.2em 0;
    line-height: 1.3;
    font-weight: 500;
  }
}

.select-option-divider {
  margin: 10px 0 !important;
}
